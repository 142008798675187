function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FEATURES_IDS, TIERS, FREE_TIER_2021_SEATS_LIMIT } from "../../../pricing";
import { getItemResourceCreditCount } from "services/ab-tests/free-tier-item-resource-credit-sync-service";
import { getIsFreeOver20ColumnTypes, getIsUnlimitedBoards } from "services/ab-tests/monetization-ab-tests-service";
import * as FEATURE_TIERS from "./features_tiers";
import { shouldShowBiggerStorageInPricingFeatures } from "services/ab-tests/acquisition-ab-test-service";
export const FILES_MANAGEMENT = {
    name: "pricingPage.features.titles.filesManagement",
    description: "pricingPage.features.descriptions.filesManagement",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const LEAD_ASSIGNMENT = {
    name: "pricingPage.features.titles.leadAssignment",
    description: "pricingPage.features.descriptions.leadAssignment",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const CUSTOMIZABLE_SALES_PROCESS = {
    name: "pricingPage.features.titles.customizableSalesProcess",
    description: "pricingPage.features.descriptions.customizableSalesProcess",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const CUSTOMER_PROJECT_MANAGEMENT = {
    name: "pricingPage.features.titles.customerProjectManagement",
    description: "pricingPage.features.descriptions.customerProjectManagement",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const WEB_TO_LEAD_CAPTURING = {
    name: "pricingPage.features.titles.webToLeadCapturing",
    description: "pricingPage.features.descriptions.webToLeadCapturing",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const CUSTOM_DASHBOARDS = {
    name: "pricingPage.features.titles.customDashboards",
    description: "pricingPage.features.descriptions.customDashboards",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const COMMUNICATION_WITH_CONTEXT = {
    name: "pricingPage.features.titles.communication_with_context",
    description: "pricingPage.features.descriptions.communication_with_context",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const COLLABORATIVE_WHITEBOARD = {
    name: "pricingPage.features.titles.collaborative_whiteboard",
    description: "pricingPage.features.descriptions.whiteboard",
    tiers: FEATURE_TIERS.WHITEBOARD
};
export const INTEGRATIONS_WITH_GMAIL_AND_OUTLOOK = {
    name: "pricingPage.features.titles.integrations_with_gmail_and_outlook",
    description: "pricingPage.features.descriptions.integrations_with_gmail_and_outlook",
    tiers: FEATURE_TIERS.INTEGRATIONS
};
export const IMPORT_FROM_OTHER_TOOLS = {
    name: "pricingPage.features.titles.importFromOtherTools",
    description: "pricingPage.features.descriptions.importFromOtherTools",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const EMBED_DOCUMENTS_AND_BRIEFS = {
    name: "pricingPage.features.titles.embed_documents_and_briefs",
    description: "pricingPage.features.descriptions.embed_documents_and_briefs",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const MARKETING_REQUESTS_WITH_FORMS = {
    name: "pricingPage.features.titles.marketing_requests_with_forms",
    description: "pricingPage.features.descriptions.marketing_requests_with_forms",
    tiers: FEATURE_TIERS.FORMS
};
export const READY_MADE_MARKETING_TEMPLATES = {
    name: "pricingPage.features.titles.ready_made_marketing_templates",
    description: "pricingPage.features.descriptions.ready_made_marketing_templates",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const TEMPLATES = {
    name: "pricingPage.features.titles.templates",
    description: "pricingPage.features.descriptions.templates",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const UPDATES_SECTION = {
    name: "pricingPage.features.titles.updatesSection",
    description: "pricingPage.features.descriptions.updatesSection",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const CUSTOMIZABLE_NOTIFICATIONS = {
    name: "pricingPage.features.titles.customizableNotifications",
    description: "pricingPage.features.descriptions.customizableNotifications",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const DEADLINE_MANAGEMENT = {
    name: "pricingPage.features.titles.deadline_management",
    description: "pricingPage.features.descriptions.deadline_management",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const SHARE_BOARD_WITH_EXTERNAL_GUESTS = {
    name: "pricingPage.features.titles.share_board_with_external_guests",
    description: "pricingPage.features.descriptions.guests",
    tiers: FEATURE_TIERS.GUESTS
};
export const MARKETING_INTEGRATIONS = {
    name: "pricingPage.features.titles.integrations",
    description: "pricingPage.features.descriptions.marketing_integrations",
    tiers: FEATURE_TIERS.INTEGRATIONS
};
export const MARKETING_DASHBOARDS = {
    name: "pricingPage.features.titles.dashboards",
    description: "pricingPage.features.descriptions.marketing_dashboards",
    tiers: FEATURE_TIERS.DASHBOARDS
};
export const MARKETING_CALENDAR = {
    name: "pricingPage.features.titles.calendar",
    description: "pricingPage.features.descriptions.marketing_calendar",
    tiers: FEATURE_TIERS.CALENDAR
};
export const DAILY_REPORTS = {
    name: "pricingPage.features.titles.daily_reports",
    description: "pricingPage.features.descriptions.daily_reports",
    tiers: FEATURE_TIERS.SCHEDULED_DASHBOARD_DELIVVERY
};
export const IMPORT_EXPORT_EXCEL = {
    name: "pricingPage.features.titles.import_export_excel",
    description: "pricingPage.features.descriptions.import_export_excel",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const WHITEBOARD = {
    name: "pricingPage.features.titles.whiteboard",
    description: "pricingPage.features.descriptions.whiteboard",
    tiers: FEATURE_TIERS.WHITEBOARD
};
export const EMBEDDED_BOARD = {
    name: "pricingPage.features.titles.embed_docs",
    description: "pricingPage.features.descriptions.embed_docs",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const ZOOM_INTEGRATION = {
    name: "pricingPage.features.titles.zoom_integration",
    description: "pricingPage.features.descriptions.zoom_integration",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: true
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const TIMELINE = ({ hasStudentPlan })=>{
    return {
        name: "pricingPage.features.titles.timeline",
        description: "pricingPage.features.descriptions.timeline",
        tiers: {
            [TIERS.FREE]: hasStudentPlan ? {
                value: true
            } : {
                value: false
            },
            [TIERS.BASIC]: {
                value: false
            },
            [TIERS.STANDARD]: {
                value: true
            },
            [TIERS.PRO]: {
                value: true
            },
            [TIERS.ENTERPRISE]: {
                value: true
            }
        }
    };
};
export const CALENDAR = ({ hasStudentPlan })=>{
    return {
        name: "pricingPage.features.titles.calendar",
        description: "pricingPage.features.descriptions.calendar",
        tiers: _object_spread_props(_object_spread({}, FEATURE_TIERS.CALENDAR), {
            [TIERS.FREE]: hasStudentPlan ? {
                value: true
            } : {
                value: false
            }
        })
    };
};
export const GUESTS = {
    name: "pricingPage.features.titles.guests",
    description: "pricingPage.features.descriptions.guests",
    tiers: FEATURE_TIERS.GUESTS
};
export const KANBAN_VIEW = {
    name: "pricingPage.features.titles.kanbanView",
    description: "pricingPage.features.descriptions.kanbanView",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const WEBINARS = {
    name: "pricingPage.features.titles.webinars",
    description: "pricingPage.features.descriptions.webinars",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const FORMS = {
    name: "pricingPage.features.titles.forms",
    description: "pricingPage.features.descriptions.forms",
    tiers: FEATURE_TIERS.FORMS
};
export const MAP = {
    name: "pricingPage.features.titles.map",
    description: "pricingPage.features.descriptions.map",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: true
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const SEARCH = {
    name: "pricingPage.features.titles.search",
    description: "pricingPage.features.descriptions.search",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: true
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const IP_RESTRICTIONS = {
    name: "pricingPage.features.titles.ip_restrictions",
    description: "pricingPage.features.descriptions.ip_restrictions",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const CONTENT_DIRECTORY = {
    name: "pricingPage.features.titles.content_directory",
    description: "pricingPage.features.descriptions.content_directory",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const TEAMS_INSIDE_TEAMS = {
    name: "pricingPage.features.titles.teams_inside_teams",
    description: "pricingPage.features.descriptions.teams_inside_teams",
    tiers: FEATURE_TIERS.ENTERPRISE_ONLY
};
export const CUSTOM_ROLES = {
    name: "pricingPage.features.titles.custom_roles",
    description: "pricingPage.features.descriptions.custom_roles",
    tiers: FEATURE_TIERS.ENTERPRISE_ONLY
};
export const SEATS_MANAGEMENT = {
    name: "pricingPage.features.titles.seats_management",
    description: "pricingPage.features.descriptions.seats_management",
    tiers: FEATURE_TIERS.ENTERPRISE_ONLY
};
export const TENANT_RESTRICTIONS = {
    name: "pricingPage.features.titles.tenant_restrictions",
    description: "pricingPage.features.descriptions.tenant_restrictions",
    tiers: FEATURE_TIERS.ENTERPRISE_ONLY
};
export const APPROVED_GUEST = {
    name: "pricingPage.features.titles.approved_guest",
    description: "pricingPage.features.descriptions.approved_guest",
    tiers: FEATURE_TIERS.ENTERPRISE_ONLY
};
export const BLOCK_DOMAINS = {
    name: "pricingPage.features.titles.block_domains",
    description: "pricingPage.features.descriptions.block_domains",
    tiers: FEATURE_TIERS.ENTERPRISE_ONLY
};
export const FORMS_CUSTOMIZATION = ({ hasStudentPlan })=>{
    return {
        name: "pricingPage.features.titles.formsCustomization",
        description: "pricingPage.features.descriptions.formsCustomization",
        tiers: {
            [TIERS.FREE]: hasStudentPlan ? {
                value: true
            } : {
                value: false
            },
            [TIERS.BASIC]: {
                value: false
            },
            [TIERS.STANDARD]: {
                value: true
            },
            [TIERS.PRO]: {
                value: true
            },
            [TIERS.ENTERPRISE]: {
                value: true
            }
        }
    };
};
export const TWO_WAY_EMAIL_SYNC = {
    name: "pricingPage.features.titles.twoWayEmailSync",
    description: "pricingPage.features.descriptions.twoWayEmailSync",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: true
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const COMMISSION_MANAGEMENT = {
    name: "pricingPage.features.titles.commissionManagement",
    description: "pricingPage.features.descriptions.commissionManagement",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const ADVANCED_CUSTOMER_PROJECT_MANAGEMENT = {
    name: "pricingPage.features.titles.advancedCustomerProjectManagement",
    description: "pricingPage.features.descriptions.advancedCustomerProjectManagement",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const WORKLOAD = {
    name: "pricingPage.features.titles.workload",
    description: "pricingPage.features.descriptions.workload",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const TIME_TRACKING = {
    name: "pricingPage.features.titles.timeTracking",
    description: "pricingPage.features.descriptions.timeTracking",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const CHART_VIEW = ({ hasStudentPlan })=>{
    return {
        name: "pricingPage.features.titles.chartView",
        description: "pricingPage.features.descriptions.chartView",
        tiers: {
            [TIERS.FREE]: hasStudentPlan ? {
                value: true
            } : {
                value: false
            },
            [TIERS.BASIC]: {
                value: false
            },
            [TIERS.STANDARD]: {
                value: false
            },
            [TIERS.PRO]: {
                value: true
            },
            [TIERS.ENTERPRISE]: {
                value: true
            }
        }
    };
};
export const FORMULA = ({ hasStudentPlan })=>{
    return {
        name: "pricingPage.features.titles.formula",
        description: "pricingPage.features.descriptions.formula",
        tiers: {
            [TIERS.FREE]: hasStudentPlan ? {
                value: true
            } : {
                value: false
            },
            [TIERS.BASIC]: {
                value: false
            },
            [TIERS.STANDARD]: {
                value: false
            },
            [TIERS.PRO]: {
                value: true
            },
            [TIERS.ENTERPRISE]: {
                value: true
            }
        }
    };
};
export const DEPENDENCY_COLUMN = {
    name: "pricingPage.features.titles.dependencyColumn",
    description: "pricingPage.features.descriptions.dependencyColumn",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const TAGS = {
    name: "pricingPage.features.titles.tags",
    description: "pricingPage.features.descriptions.tags",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const PRIVATE_BOARDS = {
    name: "pricingPage.features.titles.privateBoards",
    description: "pricingPage.features.descriptions.privateBoards",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const INTEGRATIONS = {
    name: "pricingPage.features.titles.integrations",
    description: "pricingPage.features.descriptions.integrations",
    tiers: FEATURE_TIERS.INTEGRATIONS
};
export const AUTOMATIONS = {
    name: "pricingPage.features.titles.automations",
    description: "pricingPage.features.descriptions.automations",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: "pricingPage.features.plans.automations_standard"
        },
        [TIERS.PRO]: {
            value: "pricingPage.features.plans.automations_pro"
        },
        [TIERS.ENTERPRISE]: {
            value: "pricingPage.features.plans.automations_enterprise"
        }
    }
};
export const PREMIUM_INTEGRATIONS = {
    name: "pricingPage.features.titles.premiumIntegrations",
    description: "pricingPage.features.descriptions.premiumIntegrations",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const DASHBOARDS = ({ hasStudentPlan })=>{
    return {
        name: "pricingPage.features.titles.dashboards",
        description: "pricingPage.features.descriptions.dashboards",
        tiers: _object_spread_props(_object_spread({}, FEATURE_TIERS.DASHBOARDS), {
            [TIERS.FREE]: hasStudentPlan ? {
                value: "pricingPage.features.plans.dashboards_pro"
            } : {
                value: "pricingPage.features.plans.dashboards_basic"
            }
        })
    };
};
export const TWO_FA = {
    name: "pricingPage.features.titles.twoFa",
    description: "pricingPage.features.descriptions.twoFa",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const GOOGLE = {
    name: "pricingPage.features.titles.google",
    description: "pricingPage.features.descriptions.google",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const BOARD_ADMINISTRATORS = {
    name: "pricingPage.features.titles.boardAdministrators",
    description: "pricingPage.features.descriptions.boardAdministrators",
    tiers: FEATURE_TIERS.BOARD_ADMINISTRATORS
};
export const SAML = {
    name: "pricingPage.features.titles.saml",
    description: "pricingPage.features.descriptions.saml",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const SCIM = {
    name: "pricingPage.features.titles.scimProvisioning",
    description: "pricingPage.features.descriptions.scimProvisioning",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const AUDIT_LOG = {
    name: "pricingPage.features.titles.auditLog",
    description: "pricingPage.features.descriptions.auditLog",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const SESSIONS = {
    name: "pricingPage.features.titles.sessions",
    description: "pricingPage.features.descriptions.sessions",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const PANIC_MODE = {
    name: "pricingPage.features.titles.panicMode",
    description: "pricingPage.features.descriptions.panicMode",
    tiers: FEATURE_TIERS.PANIC_MODE
};
export const HIPAA = {
    name: "pricingPage.features.titles.hipaa",
    description: "pricingPage.features.descriptions.hipaa",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const SOC = {
    name: "pricingPage.features.titles.soc",
    description: "pricingPage.features.descriptions.soc",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const SLA = {
    name: "pricingPage.features.titles.sla",
    description: "pricingPage.features.descriptions.sla",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const CS_MANAGER = {
    name: "pricingPage.features.titles.csManager",
    description: "pricingPage.features.descriptions.csManager",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const INTEGRATION_PERMISSIONS = {
    name: "pricingPage.features.titles.integrationPermissions",
    description: "pricingPage.features.descriptions.integrationPermissions",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const SALESFORCE_INTEGRATION = {
    name: "pricingPage.features.titles.salesforceIntegration",
    description: "pricingPage.features.descriptions.salesforceIntegration",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const PRIVATE_WORKSPACES = {
    name: "pricingPage.features.titles.privateWorkspaces",
    description: "pricingPage.features.descriptions.privateWorkspaces",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const ACCOUNT_PERMISSIONS = {
    name: "pricingPage.features.titles.accountPermissions",
    description: "pricingPage.features.descriptions.accountPermissions",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const WORK_PERFORMANCE_INSIGHTS = {
    name: "pricingPage.features.titles.workPerformanceInsights",
    description: "pricingPage.features.descriptions.workPerformanceInsights",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const SCHEDULED_DASHBOARD_DELIVERY = {
    name: "pricingPage.features.titles.dashboardEmailNotifications",
    description: "pricingPage.features.descriptions.dashboardEmailNotifications",
    tiers: FEATURE_TIERS.SCHEDULED_DASHBOARD_DELIVVERY
};
export const PIVOT_ANALYSIS = {
    name: "pricingPage.features.titles.pivotAnalysis",
    description: "pricingPage.features.descriptions.pivotAnalysis",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: false
        },
        [TIERS.STANDARD]: {
            value: false
        },
        [TIERS.PRO]: {
            value: false
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const KNOWLEDGE_BASE = {
    name: "pricingPage.features.titles.knowledgeBase",
    description: "pricingPage.features.descriptions.knowledgeBase",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const EMAIL_SUPPORT = {
    name: "pricingPage.features.titles.emailSupport",
    description: "pricingPage.features.descriptions.emailSupport",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const UNLIMITED_VIEWERS = {
    name: "pricingPage.features.titles.unlimitedViewers",
    description: "pricingPage.features.descriptions.viewers",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: true
        },
        [TIERS.STANDARD]: {
            value: true
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const UNLIMITED_BOARDS = {
    name: "pricingPage.features.titles.unlimitedBoards",
    description: "pricingPage.features.descriptions.boards",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const UNLIMITED_DOCS = {
    name: "pricingPage.features.titles.unlimitedDocs",
    description: "pricingPage.features.descriptions.docs",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const COLUMN_TYPES = ({ cookies = {} })=>{
    return {
        name: "pricingPage.features.titles.columnTypes",
        description: "pricingPage.features.descriptions.columnTypes",
        tiers: {
            [TIERS.FREE]: {
                value: getIsFreeOver20ColumnTypes({
                    cookies
                })
            },
            [TIERS.BASIC]: {
                value: true
            },
            [TIERS.STANDARD]: {
                value: true
            },
            [TIERS.PRO]: {
                value: true
            },
            [TIERS.ENTERPRISE]: {
                value: true
            }
        }
    };
};
export const MAXIMUM_SEATS = {
    name: "pricingPage.features.titles.maximumSeats",
    description: "pricingPage.features.descriptions.maximumSeats",
    tiers: {
        [TIERS.FREE]: {
            value: {
                key: "pricingPage.upToXSeats",
                param: FREE_TIER_2021_SEATS_LIMIT
            }
        },
        [TIERS.BASIC]: {
            value: "pricingPage.features.plans.unlimited"
        },
        [TIERS.STANDARD]: {
            value: "pricingPage.features.plans.unlimited"
        },
        [TIERS.PRO]: {
            value: "pricingPage.features.plans.unlimited"
        },
        [TIERS.ENTERPRISE]: {
            value: "pricingPage.features.plans.unlimited"
        }
    }
};
export const MOBILE = {
    name: "pricingPage.features.titles.mobile",
    description: "pricingPage.features.descriptions.mobile",
    tiers: FEATURE_TIERS.GENERIC_ALL_TRUE
};
export const STORAGE = ({ cookies, abTests })=>{
    return shouldShowBiggerStorageInPricingFeatures(abTests, cookies, false) ? BIGGER_STORAGE_FEATURE : STORAGE_FEATURE;
};
const STORAGE_FEATURE = {
    name: "pricingPage.features.titles.storage",
    id: FEATURES_IDS.STORAGE,
    description: "pricingPage.features.descriptions.storage",
    onVisible: {
        callback: ({ abTests, cookies })=>shouldShowBiggerStorageInPricingFeatures(abTests, cookies, true),
        onlyOnce: true
    },
    tiers: {
        [TIERS.FREE]: {
            value: "pricingPage.features.plans.storage_free"
        },
        [TIERS.BASIC]: {
            value: "pricingPage.features.plans.storage_basic"
        },
        [TIERS.STANDARD]: {
            value: "pricingPage.features.plans.storage_standard"
        },
        [TIERS.PRO]: {
            value: "pricingPage.features.plans.storage_pro"
        },
        [TIERS.ENTERPRISE]: {
            value: "pricingPage.features.plans.storage_enterprise"
        }
    }
};
const BIGGER_STORAGE_FEATURE = {
    name: "pricingPage.features.titles.storage",
    description: "pricingPage.features.descriptions.storage",
    id: FEATURES_IDS.STORAGE,
    onVisible: {
        callback: ({ abTests, cookies })=>shouldShowBiggerStorageInPricingFeatures(abTests, cookies, true),
        onlyOnce: true
    },
    tiers: {
        [TIERS.FREE]: {
            value: "pricingPage.features.plans.storage_free"
        },
        [TIERS.BASIC]: {
            value: "pricingPage.features.plans.storage_basic"
        },
        [TIERS.STANDARD]: {
            value: "pricingPage.features.plans.storage_standard"
        },
        [TIERS.PRO]: {
            value: "pricingPage.features.plans.storage_pro_bigger"
        },
        [TIERS.ENTERPRISE]: {
            value: "pricingPage.features.plans.storage_enterprise_bigger"
        }
    }
};
export const ACTIVITY_LOG = {
    name: "pricingPage.features.titles.activityLog",
    description: "pricingPage.features.descriptions.activityLog",
    tiers: {
        [TIERS.FREE]: {
            value: "pricingPage.features.plans.one_week"
        },
        [TIERS.BASIC]: {
            value: "pricingPage.features.plans.one_week"
        },
        [TIERS.STANDARD]: {
            value: "pricingPage.features.plans.six_months"
        },
        [TIERS.PRO]: {
            value: "pricingPage.features.plans.one_year"
        },
        [TIERS.ENTERPRISE]: {
            value: "pricingPage.features.plans.five_years"
        }
    }
};
export const ADVANCED_COLUMN_TYPES = {
    name: "pricingPage.features.titles.advancedColumnTypes",
    description: "pricingPage.features.descriptions.columnTypes",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: true
        },
        [TIERS.STANDARD]: {
            value: true
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const VIEWERS = {
    name: "pricingPage.features.titles.viewers",
    description: "pricingPage.features.descriptions.viewers",
    tiers: {
        [TIERS.FREE]: {
            value: false
        },
        [TIERS.BASIC]: {
            value: true
        },
        [TIERS.STANDARD]: {
            value: true
        },
        [TIERS.PRO]: {
            value: true
        },
        [TIERS.ENTERPRISE]: {
            value: true
        }
    }
};
export const BOARDS = {
    name: "pricingPage.features.titles.boards",
    description: "pricingPage.features.descriptions.boards",
    tiers: {
        [TIERS.FREE]: {
            value: "pricingPage.features.plans.unlimited"
        },
        [TIERS.BASIC]: {
            value: "pricingPage.features.plans.unlimited"
        },
        [TIERS.STANDARD]: {
            value: "pricingPage.features.plans.unlimited"
        },
        [TIERS.PRO]: {
            value: "pricingPage.features.plans.unlimited"
        },
        [TIERS.ENTERPRISE]: {
            value: "pricingPage.features.plans.unlimited"
        }
    }
};
export const ITEMS = ({ abTests, cookies })=>{
    return {
        name: "pricingPage.features.titles.items",
        description: {
            key: "pricingPage.features.descriptions.items",
            param: getItemResourceCreditCount(abTests, cookies)
        },
        tiers: {
            [TIERS.FREE]: {
                value: "pricingPage.features.plans.upToOneK"
            },
            [TIERS.BASIC]: {
                value: "pricingPage.features.plans.unlimited"
            },
            [TIERS.STANDARD]: {
                value: "pricingPage.features.plans.unlimited"
            },
            [TIERS.PRO]: {
                value: "pricingPage.features.plans.unlimited"
            },
            [TIERS.ENTERPRISE]: {
                value: "pricingPage.features.plans.unlimited"
            }
        }
    };
};
export const UNLIMITED_BOARDS_WITH_FREE_TIER = ({ cookies = {}, abTests = [] })=>{
    return {
        name: "pricingPage.features.titles.unlimitedBoards",
        description: "pricingPage.features.descriptions.boards",
        tiers: {
            [TIERS.FREE]: {
                value: getIsUnlimitedBoards({
                    cookies,
                    abTests
                })
            },
            [TIERS.BASIC]: {
                value: true
            },
            [TIERS.STANDARD]: {
                value: true
            },
            [TIERS.PRO]: {
                value: true
            },
            [TIERS.ENTERPRISE]: {
                value: true
            }
        }
    };
};
