export const leadSourceDefaultValue = "Website - Contact Sales";
export const leadSourceConfigList = [
    {
        value: "Website - Contact Sales",
        text: "Website - Contact Sales"
    },
    {
        value: "Website - Request a Demo",
        text: "Website - Request a Demo"
    },
    {
        value: "Linkedin - Contact Sales",
        text: "Linkedin - Contact Sales"
    },
    {
        value: "Linkedin - Request a Demo",
        text: "Linkedin - Request a Demo"
    },
    {
        value: "Content",
        text: "Content"
    },
    {
        value: "Contact Sales - Tech Partner",
        text: "Contact Sales - Tech Partner"
    },
    {
        value: "Webinar",
        text: "Webinar"
    },
    {
        value: "Mobile - Contact Sales",
        text: "Mobile - Contact Sales"
    },
    {
        value: "Xing",
        text: "Xing"
    },
    {
        value: "Website - Nurture Contact Sales",
        text: "Website - Nurture Contact Sales"
    },
    {
        value: "Event",
        text: "Event"
    }
];
export const CUSTOM_EVENTS = {
    FORM_SUBMISSION: "pe5945317_website_contact_sales_form_submission",
    ELIGIBLE_FOR_WEBSITE_BOT: "pe5945317_eligible_for_website_bot"
};
export const HUBSPOT_API_FIRSTNAME = "firstname";
export const HUBSPOT_API_LASTNAME = "lastname";
export const HUBSPOT_API_EMAIL = "email";
export const HUBSPOT_API_JOB = "jobtitle";
export const HUBSPOT_API_PHONE = "phone";
export const HUBSPOT_API_COMPANY = "company";
export const HUBSPOT_API_COMPANY_SIZE = "company_size__c";
export const HUBSPOT_API_PRODUCT_QUESTION = "product_question_hs_forms";
export const HUBSPOT_API_HOW_CAN_HELP = "how_can_our_consulting_team_help_you";
export const HUBSPOT_CONTACT_SALES_AI_ANALYZER_FIELD_NAME = "contact_sales_ai_analyzer";
export const HUBSPOT_API_CLUSTER = "cluster_hs";
export const HUBSPOT_API_VISITOR = "visitor_id";
export const HUBSPOT_API_SOURCE = "leadsource";
export const HUBSPOT_API_FROM = "from";
export const HUBSPOT_API_PRIVACY_CONSENT = "seen_legal_disclaimer";
export const HUBSPOT_API_PRICING_VERSION = "website_pricing_version";
export const HUBSPOT_API_RECENT_CONVERSION_URL = "recent_conversion_url";
export const HUBSPOT_API_RECENT_CONVERSION_REFERRER_PAGE = "recent_conversion_referrer_page";
export const HUBSPOT_API_AB_TEST_NAME = "web_presence_test_name";
export const HUBSPOT_API_UTM_SOURCE = "utm_source";
export const HUBSPOT_API_UTM_CAMPAIGN = "utm_campaign";
export const HUBSPOT_API_UTM_TERM = "utm_term";
export const HUBSPOT_API_UTM_BANNER = "utm_banner";
export const HUBSPOT_API_UTM_CONTENT = "utm_content";
export const HUBSPOT_API_UTM_MEDIUM = "utm_medium";
export const HUBSPOT_ELIGIBLE_FOR_BAM = "eligible_for_book_a_meeting";
const HUBSPOT_API_UTM_ADGROUP = "utm_adgroup";
const HUBSPOT_API_UTM_KEYWORD = "utm_keyword";
const HUBSPOT_API_NUMBER_OF_SEATS = "number_of_seats_last_selected_pricing_page";
export const URL_PARAM_FROM = "from";
export const PII_PARAMS = [
    HUBSPOT_API_FIRSTNAME,
    HUBSPOT_API_LASTNAME,
    HUBSPOT_API_EMAIL,
    HUBSPOT_API_PHONE
];
/* this is {label: String, value: String} objects list to fit the react-select input,
changing the values will break the form (in hubspot) */ export const productQuestionOptionsList = [
    {
        label: "contactSales.hubspotForm.form.productQuestion.workManagement",
        value: "Work & project management"
    },
    {
        label: "contactSales.hubspotForm.form.productQuestion.crm",
        value: "Sales & CRM"
    },
    {
        label: "contactSales.hubspotForm.form.productQuestion.product",
        value: "Product management"
    },
    {
        label: "contactSales.hubspotForm.form.productQuestion.softwareDevelopment",
        value: "Software development"
    },
    {
        label: "contactSales.hubspotForm.form.productQuestion.service",
        value: "Ticketing & service management"
    },
    {
        label: "contactSales.hubspotForm.form.productQuestion.other",
        value: "Other"
    }
];
// The UTM consts are used as both url param names and keys in the local storage
const UTM_SOURCE = "utm_source";
const UTM_CAMPAIGN = "utm_campaign";
const UTM_BANNER = "utm_banner";
const UTM_CONTENT = "utm_content";
const UTM_ADGROUP = "utm_adgroup";
const UTM_MEDIUM = "utm_medium";
const UTM_KEYWORD = "utm_keyword";
export const NUMBER_OF_SEATS_PARAM = "number_of_seats_last_selected_pricing_page";
export const AI_ANALYZER_VALUES = {
    SALES: "sales",
    SUPPORT: "support",
    UNKNOWN: "unknown",
    ERROR: "error"
};
export const SALES_ENRICHMENT_PARAM_TYPES = {
    URL_PARAM: "url_param"
};
export const AI_CONTACT_SALES_ANALYZER_RESPONSE_TO_HUBSPOT_FIELD_MAP = {
    [AI_ANALYZER_VALUES.SALES]: "Sales",
    [AI_ANALYZER_VALUES.SUPPORT]: "Support",
    [AI_ANALYZER_VALUES.UNKNOWN]: "Empty",
    [AI_ANALYZER_VALUES.ERROR]: "Error"
};
export const SALES_ENRICHMENT_PARAM_LIST = [
    {
        type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
        hubspotApiName: HUBSPOT_API_UTM_SOURCE,
        paramName: UTM_SOURCE
    },
    {
        type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
        hubspotApiName: HUBSPOT_API_UTM_CAMPAIGN,
        paramName: UTM_CAMPAIGN
    },
    {
        type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
        hubspotApiName: HUBSPOT_API_UTM_BANNER,
        paramName: UTM_BANNER
    },
    {
        type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
        hubspotApiName: HUBSPOT_API_UTM_CONTENT,
        paramName: UTM_CONTENT
    },
    {
        type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
        hubspotApiName: HUBSPOT_API_UTM_ADGROUP,
        paramName: UTM_ADGROUP
    },
    {
        type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
        hubspotApiName: HUBSPOT_API_UTM_MEDIUM,
        paramName: UTM_MEDIUM
    },
    {
        type: SALES_ENRICHMENT_PARAM_TYPES.URL_PARAM,
        hubspotApiName: HUBSPOT_API_UTM_KEYWORD,
        paramName: UTM_KEYWORD
    },
    {
        hubspotApiName: HUBSPOT_API_NUMBER_OF_SEATS,
        paramName: NUMBER_OF_SEATS_PARAM
    }
];
