function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { IPO_CAMPAIGN_ID } from "constants/sales/campaigns";
import { getHubspotHowCanHelpInput, HUBSPOT_COMPANY_INPUT, HUBSPOT_COMPANY_INPUT_WITH_PLACEHOLDER, HUBSPOT_COMPANYSIZE_INPUT, HUBSPOT_COMPANYSIZE_INPUT_WITH_PLACEHOLDER, HUBSPOT_EMAIL_INPUT, HUBSPOT_EMAIL_INPUT_WITH_PLACEHOLDER, HUBSPOT_FIRSTNAME_INPUT, HUBSPOT_FIRSTNAME_INPUT_WITH_PLACEHOLDER, HUBSPOT_HOWPLANUSE_INPUT, HUBSPOT_JOBTITLE_INPUT, HUBSPOT_JOBTITLE_INPUT_WITH_PLACEHOLDER, HUBSPOT_LASTNAME_INPUT, HUBSPOT_LASTNAME_INPUT_WITH_PLACEHOLDER, HUBSPOT_PHONE_INPUT, HUBSPOT_PRODUCT_QUESTION_INPUT, HUBSPOT_PRODUCT_QUESTION_INPUT_WITH_PLACEHOLDER } from "./hubspot-inputs";
export const PORTAL_ID = "5945317";
export const TEST_PORTAL_ID = "22796107";
export const CONTACT_SALES_HUBSPOT_FORM_ID = "215c1dc9-0c3c-44ad-be61-add5f71dfe22";
export const REQUEST_A_DEMO_HUBSPOT_FORM_ID = "1027e76f-6a94-489b-8ee3-e6d8141cc0f6";
export const REPORT_FORM_ID = "1091544b-bff2-40ef-aada-8acfea9089b3";
export const NURTURE_CONTACT_US_FORM_ID = "38593c14-af5b-4f56-8d3b-a403dee4266b";
export const EMAIL_ONLY_FORM_ID = "c46e9209-b721-472f-8c2e-b151cba6d12e";
export const EVENTS_HUBSPOT_FORM_ID = "192ded92-8c95-44bd-bf4e-1fcf0a32c0c2";
// used for testing only
export const TEST_CONTACT_SALES_HUBSPOT_FORM_ID = "6fe93320-3033-4c28-9215-c19b535ebcae";
const getOverrideParamsForCampaigin = (campaignId)=>{
    if (campaignId === IPO_CAMPAIGN_ID) {
        return {
            titleKey: "contactSales.hubspotForm.form.ipoCampaignFieldTitle.howCanHelp",
            placeholderKey: "contactSales.hubspotForm.form.ipoCampaignFieldPlaceholder.howCanHelp"
        };
    }
    return null;
};
const getContactSalesConfig = ({ formId, campaignId, withSubmitNotice, formTitle, lastNameFirst, isV2, firstFormSubmitted })=>{
    const overrideParams = getOverrideParamsForCampaigin(campaignId);
    const getFirstNameLastNameRow = (withPlaceHolder = false)=>{
        const row = withPlaceHolder ? [
            HUBSPOT_FIRSTNAME_INPUT_WITH_PLACEHOLDER,
            HUBSPOT_LASTNAME_INPUT_WITH_PLACEHOLDER
        ] : [
            HUBSPOT_FIRSTNAME_INPUT,
            HUBSPOT_LASTNAME_INPUT
        ];
        return lastNameFirst ? row.reverse() : row;
    };
    const getProductQuestionRow = (withPlaceHolder = false)=>{
        if (withPlaceHolder) {
            return HUBSPOT_PRODUCT_QUESTION_INPUT_WITH_PLACEHOLDER;
        }
        return HUBSPOT_PRODUCT_QUESTION_INPUT;
    };
    const getMainFormConfig = ()=>{
        if (isV2) {
            return {
                formTitle,
                rows: [
                    getFirstNameLastNameRow(true),
                    [
                        HUBSPOT_EMAIL_INPUT_WITH_PLACEHOLDER
                    ],
                    [
                        HUBSPOT_JOBTITLE_INPUT_WITH_PLACEHOLDER
                    ],
                    [
                        HUBSPOT_PHONE_INPUT
                    ],
                    [
                        HUBSPOT_COMPANY_INPUT_WITH_PLACEHOLDER,
                        HUBSPOT_COMPANYSIZE_INPUT_WITH_PLACEHOLDER
                    ],
                    [
                        getProductQuestionRow(true)
                    ],
                    [
                        getHubspotHowCanHelpInput(overrideParams)
                    ]
                ],
                submitButtonText: withSubmitNotice ? "contactSales.hubspotForm.form.submitButton" : "contactSales.hubspotForm.form.contactUsButton"
            };
        }
        return {
            formTitle,
            rows: [
                getFirstNameLastNameRow(),
                [
                    HUBSPOT_EMAIL_INPUT,
                    HUBSPOT_JOBTITLE_INPUT
                ],
                [
                    HUBSPOT_PHONE_INPUT
                ],
                [
                    HUBSPOT_COMPANY_INPUT,
                    HUBSPOT_COMPANYSIZE_INPUT
                ],
                [
                    getProductQuestionRow()
                ],
                [
                    getHubspotHowCanHelpInput(overrideParams)
                ]
            ],
            submitButtonText: withSubmitNotice ? "contactSales.hubspotForm.form.submitButton" : "contactSales.hubspotForm.form.contactUsButton"
        };
    };
    switch(formId){
        case CONTACT_SALES_HUBSPOT_FORM_ID:
        case TEST_CONTACT_SALES_HUBSPOT_FORM_ID:
            return getMainFormConfig();
        case REQUEST_A_DEMO_HUBSPOT_FORM_ID:
            return {
                rows: [
                    getFirstNameLastNameRow(),
                    [
                        HUBSPOT_EMAIL_INPUT,
                        HUBSPOT_JOBTITLE_INPUT
                    ],
                    [
                        HUBSPOT_PHONE_INPUT
                    ],
                    [
                        HUBSPOT_COMPANY_INPUT,
                        HUBSPOT_COMPANYSIZE_INPUT
                    ],
                    [
                        getProductQuestionRow()
                    ],
                    [
                        HUBSPOT_HOWPLANUSE_INPUT
                    ]
                ]
            };
        case REPORT_FORM_ID:
            return {
                rows: [
                    getFirstNameLastNameRow(),
                    [
                        HUBSPOT_EMAIL_INPUT,
                        HUBSPOT_JOBTITLE_INPUT
                    ],
                    [
                        HUBSPOT_PHONE_INPUT
                    ],
                    [
                        HUBSPOT_COMPANY_INPUT,
                        HUBSPOT_COMPANYSIZE_INPUT
                    ]
                ],
                submitButtonText: "contactSales.hubspotForm.form.getReport"
            };
        case NURTURE_CONTACT_US_FORM_ID:
            return {
                rows: [
                    getFirstNameLastNameRow(),
                    [
                        HUBSPOT_EMAIL_INPUT
                    ],
                    [
                        getProductQuestionRow()
                    ],
                    [
                        getHubspotHowCanHelpInput(overrideParams)
                    ]
                ],
                submitButtonText: "contactSales.hubspotForm.form.getInTouch",
                privacyNoticeText: "forms.getInTouch.privacyNotice"
            };
        case EMAIL_ONLY_FORM_ID:
            return {
                rows: [
                    [
                        HUBSPOT_EMAIL_INPUT
                    ]
                ],
                submitButtonText: "Submit"
            };
        case EVENTS_HUBSPOT_FORM_ID:
            return {
                formTitle,
                rows: [
                    getFirstNameLastNameRow(),
                    [
                        HUBSPOT_EMAIL_INPUT,
                        HUBSPOT_JOBTITLE_INPUT
                    ],
                    [
                        HUBSPOT_PHONE_INPUT
                    ],
                    [
                        HUBSPOT_COMPANY_INPUT,
                        HUBSPOT_COMPANYSIZE_INPUT
                    ]
                ],
                submitButtonText: "contactSales.hubspotForm.form.register"
            };
        default:
            return {
                rows: []
            };
    }
};
export const getContactFormConfigById = ({ formId, overrideCampaignId = null, withSubmitNotice = true, formTitle, lastNameFirst, isV2, firstFormSubmitted, privacyNoticeText, ctaProps = {} })=>{
    const formData = getContactSalesConfig({
        formId,
        overrideCampaignId,
        withSubmitNotice,
        formTitle,
        lastNameFirst,
        isV2,
        firstFormSubmitted
    });
    return _object_spread_props(_object_spread({}, formData), {
        ctaProps,
        privacyNoticeText: privacyNoticeText || formData.privacyNoticeText
    });
};
